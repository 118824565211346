import { trimWhitespaceFromValues } from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        baseColor: !values.baseColor && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
    };

    if (values.subject === 'Name Meaning') {
        errors.firstName = !values.firstName && 'Required';
        errors.nameMeaning = !values.nameMeaning && 'Required';
        errors.whoChoseTheName = !values.whoChoseTheName && 'Required';
        errors.choseItBecause = !values.choseItBecause && 'Required';
    }

    if (values.subject === 'Birth Story') {
        errors.firstName = !values.firstName && 'Required';
        errors.birthDate = !values.birthDate && 'Required';
        errors.timeOfDay = !values.timeOfDay && 'Required';
        errors.weatherThatDay = !values.weatherThatDay && 'Required';
        errors.birthStoryHowMany = !values.birthStoryHowMany && 'Required';
        errors.typeOfBirth = !values.typeOfBirth && 'Required';
        errors.whoWillNeverForget = !values.whoWillNeverForget && 'Required';
        errors.weWillNeverForget = !values.weWillNeverForget && 'Required';
    }

    if (values.subject === 'Adoption Story') {
        errors.firstName = !values.firstName && 'Required';
        errors.birthDate = !values.birthDate && 'Required';
        errors.adoptionDate = !values.adoptionDate && 'Required';
        errors.dateHeaderOptions = !values.dateHeaderOptions && 'Required';
        errors.birthStoryHowMany = !values.birthStoryHowMany && 'Required';
        errors.whoWillNeverForget = !values.whoWillNeverForget && 'Required';
        errors.weWillNeverForget = !values.weWillNeverForget && 'Required';
    }

    if (values.subject === 'Custom Note') {
        errors.customField = !values.customField && 'Required';
    }

    return errors;
};
