import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './FilterSelector.module.css';

const filterOptions = [
    { name: 'None', key: 'default' },
    { name: 'Color', key: 'color' },
    { name: 'Black & White', key: 'bw' },
];

const FilterSelector = ({ input, meta: { touched, error } }) => {
    return (
        <div
            className={cx(styles.root, {
                [styles['has-error']]: touched && error,
            })}
        >
            <h3 className="form-title">Filters</h3>
            <div className={styles.options}>
                {filterOptions.map((filter, i) => {
                    const selected = input.value === filter.key;
                    return (
                        <label
                            key={filter.name}
                            className={cx(styles.option, {
                                [styles.selected]: selected,
                            })}
                        >
                            <span className={'body1'}>{filter.name}</span>
                            <input
                                {...input}
                                value={filter.key}
                                checked={selected}
                                type="radio"
                            />
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

FilterSelector.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.any,
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

export default FilterSelector;
