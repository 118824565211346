import moment from 'moment';
import actions from 'redux-form/es/actions';
import { put } from 'redux-saga/effects';
import { actionTypes } from 'stillnovel/store/project/actions';
import {
    scopedMetaUpdateHandler,
    handleImageMeta,
} from 'stillnovel/store/project/shared';

function* handleStyle() {
    // clear any incompatible values here on style change
    yield put(actions.change('project', 'baseColor', 'SOFT_WHITE'));
}

function* handleBirthDate({ payload }) {
    const { value } = payload;

    yield put({
        type: actionTypes.UPDATE_META_VALUE,
        payload: {
            field: 'birthDate',
            value: moment(value).format(),
        },
    });
}

const derivedHandlers = {
    style: handleStyle,
    imageMeta: handleImageMeta,
    birthDate: handleBirthDate,
};

export default scopedMetaUpdateHandler(
    [
        'note-to-you-adoption-story',
        'note-to-you-birth-story',
        'note-to-you-custom-note',
        'note-to-you-name-meaning',
    ],
    derivedHandlers
);
