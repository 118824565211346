import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Col.module.css';

const Col = ({ children, colSpan, tag, style }) => {
    const TagName = tag;
    const classNames = cx(styles.col, styles[`col-${colSpan}`]);
    return (
        <TagName className={classNames} style={style}>
            {children}
        </TagName>
    );
};

Col.propTypes = {
    children: PropTypes.node.isRequired,
    colSpan: PropTypes.number,
    tag: PropTypes.string,
    style: PropTypes.object,
};

Col.defaultProps = {
    colSpan: null,
    tag: 'div',
};

export default Col;
