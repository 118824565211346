import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';

import FieldInput from 'stillnovel/components/UI/Input/FieldInput';

import Form from 'stillnovel/components/UI/Form';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import TextPreview from 'stillnovel/components/UI/TextPreview';

import NoteToYouStyle01 from '@stillnovel/artistry/lib/NoteToYouStyle01';
import NoteToYouStyle02 from '@stillnovel/artistry/lib/NoteToYouStyle02';

import validate from '../validate';

import styles from './Details.module.css';

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    submitting,
    project,
}) => {
    const {
        metadata: {
            firstName,
            nameMeaning,
            whoChoseTheName,
            choseItBecause,
            weatherThatDay,
            typeOfBirth,
            timeOfDay,
            birthDate,
            adoptionDate,
            birthStoryHowMany,
            birthStoryTimeUnits,
            birthStoryChoice,
            whoWillNeverForget,
            weWillNeverForget,
            adoptionStoryTimeUnits,
            adoptionStoryChoice,
            subject,
            customField,
            style,
        },
    } = project;

    const birthDateParsed = birthDate ? moment.parseZone(birthDate) : false;
    const adoptionDateParsed = adoptionDate
        ? moment.parseZone(adoptionDate)
        : false;

    const birthStoryTimeUnitsOptions = ['Hours', 'Weeks', 'Months'];

    const birthStoryChoiceOptions = ['Labor', 'Pregnancy'];

    const adoptionStoryTimeUnitsOptions = ['Weeks', 'Months', 'Years'];

    const adoptionStoryChoiceOptions = ['Waiting', 'Searching', 'Praying'];

    const typeOfBirthOptions = [
        'At Home',
        'Naturally',
        'via Surprise Cesarean',
        'via Cesarean',
        'via Surrogate',
        'Omit',
    ];

    const whoWillNeverForgetOptions = [
        'I will never forget',
        'We will never forget',
    ];

    const renderSentence =
        style === 'Style 01'
            ? NoteToYouStyle01.renderSentence
            : NoteToYouStyle02.renderSentence;

    const addEmsForPreviewText = true;

    const previewSentence = renderSentence(
        firstName,
        nameMeaning,
        whoChoseTheName,
        choseItBecause,
        weatherThatDay,
        typeOfBirth,
        birthDateParsed,
        timeOfDay,
        birthStoryHowMany,
        birthStoryTimeUnits,
        birthStoryChoice,
        whoWillNeverForget,
        weWillNeverForget,
        adoptionDate,
        adoptionDateParsed,
        adoptionStoryTimeUnits,
        adoptionStoryChoice,
        subject,
        customField,
        addEmsForPreviewText
    );

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row
                        alignment="bottom"
                        className={
                            subject === 'Birth Story' ||
                            subject === 'Adoption Story'
                                ? styles.flex
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="birthStoryHowMany"
                                className={'form-title'}
                            >
                                {subject === 'Birth Story'
                                    ? 'Labor or Pregancy Timeline'
                                    : 'Adoption Timeline'}
                            </label>
                            <div className={styles.flex}>
                                <div>
                                    <Field
                                        id="birthStoryHowMany"
                                        name="birthStoryHowMany"
                                        type="text"
                                        component={FieldInput}
                                        placeholder="Ex: “twelve”"
                                    />
                                </div>
                                <div
                                    className={
                                        subject === 'Birth Story'
                                            ? styles.flex
                                            : styles.hide
                                    }
                                >
                                    <Field
                                        id="birthStoryTimeUnits"
                                        name="birthStoryTimeUnits"
                                        component={FieldSelect}
                                    >
                                        <option value="" disabled>
                                            --
                                        </option>
                                        {birthStoryTimeUnitsOptions.map(
                                            (option, i) => (
                                                <option key={i} value={option}>
                                                    {option}
                                                </option>
                                            )
                                        )}
                                    </Field>
                                </div>

                                <div
                                    className={
                                        subject === 'Adoption Story'
                                            ? styles.block
                                            : styles.hide
                                    }
                                >
                                    <Field
                                        id="adoptionStoryTimeUnits"
                                        name="adoptionStoryTimeUnits"
                                        component={FieldSelect}
                                    >
                                        <option value="" disabled>
                                            --
                                        </option>
                                        {adoptionStoryTimeUnitsOptions.map(
                                            (option, i) => (
                                                <option key={i} value={option}>
                                                    {option}
                                                </option>
                                            )
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className={styles.flex}>
                                <div className={styles.storyLabel}>
                                    <span className={'caption'}>OF</span>
                                </div>
                                <div
                                    className={
                                        subject === 'Birth Story'
                                            ? styles.flex
                                            : styles.hide
                                    }
                                >
                                    <Field
                                        id="birthStoryChoice"
                                        name="birthStoryChoice"
                                        component={FieldSelect}
                                    >
                                        <option value="" disabled>
                                            --
                                        </option>
                                        {birthStoryChoiceOptions.map(
                                            (option, i) => (
                                                <option key={i} value={option}>
                                                    {option}
                                                </option>
                                            )
                                        )}
                                    </Field>
                                </div>
                                <div
                                    className={
                                        subject === 'Adoption Story'
                                            ? styles.flex
                                            : styles.hide
                                    }
                                >
                                    <Field
                                        id="adoptionStoryChoice"
                                        name="adoptionStoryChoice"
                                        component={FieldSelect}
                                        stretch
                                    >
                                        <option value="" disabled>
                                            --
                                        </option>
                                        {adoptionStoryChoiceOptions.map(
                                            (option, i) => (
                                                <option key={i} value={option}>
                                                    {option}
                                                </option>
                                            )
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row
                        className={
                            subject === 'Birth Story'
                                ? styles.flex
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="typeOfBirth"
                                className={'form-title'}
                            >
                                Type Of Birth
                            </label>
                            <Field
                                id="typeOfBirth"
                                name="typeOfBirth"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {typeOfBirthOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>

                    <Row
                        className={
                            subject === 'Birth Story' ||
                            subject === 'Adoption Story'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="whoWillNeverForget"
                                className={'form-title'}
                            >
                                Will Never Forget
                            </label>
                            <Field
                                id="whoWillNeverForget"
                                name="whoWillNeverForget"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {whoWillNeverForgetOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>

                        <Col>
                            <Field
                                stretch
                                id="weWillNeverForget"
                                name="weWillNeverForget"
                                type="textarea"
                                component={FieldInput}
                                maxLength={style === 'Style 01' ? '200' : '48'}
                                showErrorText
                                placeholder="Ex: “the first night sleeping with you in our arms”"
                            />
                        </Col>
                        <Col>
                            <TextPreview string={`${previewSentence}`} />
                        </Col>
                    </Row>

                    {style === 'Style01' && (
                        <Row>
                            <Col>
                                <label
                                    htmlFor="bylineOrCaption"
                                    className={'form-title'}
                                >
                                    Optional Signature Or Caption
                                </label>
                                <Field
                                    stretch
                                    id="bylineOrCaption"
                                    name="bylineOrCaption"
                                    type="text"
                                    component={FieldInput}
                                    maxLength="60"
                                    showErrorText
                                    placeholder="Ex: “love, Mom” or “photo taken at 2 days old”"
                                />
                            </Col>
                        </Row>
                    )}
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    project: PropTypes.object.isRequired,
};

Details.defaultProps = {
    invalid: false,
    submitting: false,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
