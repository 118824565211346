import { all } from 'redux-saga/effects';

const sagas = [
    ...require('stillnovel/store/auth/saga').default,
    ...require('stillnovel/store/cart/saga').default,
    ...require('stillnovel/store/project/saga').default,
    ...require('stillnovel/store/projects/saga').default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/AllAboutYou/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/BackToSchoolSnapshot/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/NoteToYou/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/BirthStat/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/BirthStatSnapshot/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Travel/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/TravelSnapshot/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Simple/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Wedding/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Grandparent/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Parent/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Couples/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Engagement/saga')
        .default,
    ...require('stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Pet/saga')
        .default,
];

export default function* rootSaga() {
    yield all([...sagas]);
}
