import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Input from './Input';
import styles from './FieldInput.module.css';

const FieldInput = ({ inline = false, input, meta, maxLength, ...props }) => {
    const charCount = input.value?.length || 0;
    const charsLeft = maxLength ? maxLength - charCount : null;
    return (
        <div className={cx(styles.field, { [styles.inline]: inline })}>
            <Input
                {...input}
                {...props}
                maxLength={maxLength}
                touched={meta.touched}
                error={meta.error}
            />
            {maxLength && input.value && (
                <div className={`body1 ${styles.characterCount}`}>
                    {charsLeft} chars left
                </div>
            )}
        </div>
    );
};

FieldInput.propTypes = {
    inline: PropTypes.bool,
    input: PropTypes.object.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any,
    }).isRequired,
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default memo(FieldInput);
